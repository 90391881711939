// Variables
//@use 'variables' as vars;
// Bootstrap

@use 'bootstrap/scss/bootstrap' with (
    $body-bg: #f8fafc,
    // Typography
    //$font-size-base: 1rem,
    //$line-height-base: 1.6,
    // ** Tooltip background color
    $tooltip-bg: #6c757d,
    $tooltip-opacity: 1
) ;

// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

.border-5 {
	border-width: 5px !important;
}

.modal-90w {
	width: 90%;
	max-width: none !important;
}

.modal-50w {
	width: 50%;
	max-width: none !important;
}

.english-mark {
	color: purple;
	font-weight: bold;
}

$font-size-base: 0.95rem;

.texteSyllabus {
//	font-family: Georgia, 'Times New Roman', Times, serif;
	line-height: 1.4;
    font-size: $font-size-base;
}

.texteSyllabus h1,
.texteSyllabus h2,
.texteSyllabus h3,
.texteSyllabus h4,
.texteSyllabus h5,
.texteSyllabus h6 {
	margin-top: 0;
	margin-bottom: 0.4rem;
	line-height: 1.2;
}

.texteSyllabus p {
	margin-top: 0;
	margin-bottom: 0.8rem;
}

.texteSyllabus h1,
.texteSyllabus .h1 {
	font-size: $font-size-base *1.6;
}

.texteSyllabus h2,
.texteSyllabus .h2 {
	font-size: $font-size-base *1.46;
}

.texteSyllabus h3,
.texteSyllabus .h3 {
	font-size: $font-size-base *1.33;
	font-weight: bold;
}

.texteSyllabus h4,
.texteSyllabus .h4 {
	font-size: $font-size-base *1.21;
	text-transform: uppercase;
	font-weight: bold;
}

.texteSyllabus h5,
.texteSyllabus .h5 {
	font-size: $font-size-base * 1.1;
	font-style: italic;
	font-weight: bold;
}

.texteSyllabus h6,
.texteSyllabus .h6 {
	font-size: $font-size-base;
	font-style: italic;
}

.btnSyllabus:hover {
	background: gainsboro;
	border-radius: 4px;
}
